import React from "react"
import "../style/amber.css"
import Layout from "../components/layout"
import "../style/tab.css"

class Amber extends React.Component {
    render() {
        return (
            <Layout>
                <br /><br /><br /><br />
                {/* Amber Slider */}
                <div id="carouselExampleIndicators" className="carousel slide z-9" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
			{/*<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>*/}
                    </ol>
                    <div className="carousel-inner">
			<div className="carousel-item active">
			<img className="d-block w-100" src="../Images/Aspire Amber.jpg" alt="First slide" />
			</div>
			{/*<div className="carousel-item active">
                            <img className="d-block w-100" src="../Images/amsl.jpg" alt="First slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src="../Images/amsl2.jpg" alt="Second slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src="../Images/amsl3.jpg" alt="Third slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src="../Images/amsl4.jpg" alt="Fourth slide" />
                        </div>*/}
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                {/* Aspire Amber */}
                <img src="../Images/lr.png" alt="lr" className="l-aspire" />
                <div className="container pl-5 pr-5 ">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <img src="../Images/AmberLo.png" alt="aurum" className="img-aurum" style={{ width: "100px" }}/>
                            <p><b>RERA No.: PRM/KA/RERA/1251/446/PR/250222/004731</b></p>
                            <p className=" pb-5 mob-justify lh-2">Aspire Amber by Sumadhura is a collection of 2, 3 BHK real value homes in the heart of the IT boom belt in Bengaluru. Tucked away neatly, yet close to all the buzzing activities of life. A successor to Aurum, this is the second project of the popular Aspire series. The sheer affordability of upscale amenities places it incredibly ahead of more expensive projects. A prime, central location. Just minutes away from everything you need, from work to entertainment to education and healthcare. A stone's throw from EPIP and the gates of ITPB. Amber is close to malls, entertainment hubs, schools, hospitals and more. The connectivity is a bonus. Amber's connectivity, including proximity to the upcoming Metro line, makes it a truly attractive proposition.</p>
                        </div>
                    </div>
                </div>
                {/* Master Plan */}
                <div className="bg-aspiregray pt-4 pb-4 pl-5 pr-5 f-plan">
                    <h2 class="aspireblue text-center text-Montserrat-2 pb-4">MASTER PLAN</h2>
					<img src="../Images/amber_Master_Plan.jpg" alt="Master Plan" class="w-100 f-plan" data-toggle="modal" data-target=".amber_Master_Plan"/>
                </div>
                {/* Tab Switch  */}
                <div className="bg-aspireblue pt-5 pb-3 pl-4 pr-4">
                    <div className="container bg-aspireblue">
                        <div class="bs-example">
                            <ul class="nav nav-tabs ">
                                <li class="nav-item">
                                    <a href="#floorplan" class="nav-link active" data-toggle="tab">FLOOR PLANS</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#images" class="nav-link fill-nav" data-toggle="tab">IMAGES</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#amenities" class="nav-link" data-toggle="tab">AMENITIES</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#spec" class="nav-link" data-toggle="tab">SPECIFICATIONS</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#walk" class="nav-link" data-toggle="tab">WALKTHROUGH</a>
                                </li>
                            </ul>
                            <div class="tab-content pt-5 pb-5">
                                <div class="tab-pane fade show active" id="floorplan">
                                    <div className="row tab-1 text-justify">
                                        <div className="row pb-4">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/Amber_FP_1.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f11" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/Amber_FP_2.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f12" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/Amber_FP_3.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f13" />
                                            </div>
                                        </div>
                                        <div className="row pb-4">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/Amber_FP_4.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f14" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/Amber_FP_5.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f15" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/Amber_FP_6.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f16" />
                                            </div>
                                        </div>
                                        <div className="row pb-4">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/Amber_FP_7.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f17" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/Amber_FP_8.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f18" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/Amber_FP_9.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f19" />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/Amber_FP_10.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f20" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/Amber_FP_11.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f21" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="images">
                                    <div className="row tab-1 text-justify" >
                                        <div className="row">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/amber1.jpg" className="slide-img" data-toggle="modal" data-target=".amber1" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/amber2.jpg" className="slide-img" data-toggle="modal" data-target=".amber2" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/amber3.jpg" className="slide-img" data-toggle="modal" data-target=".amber3" style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/amber4.jpg" className="slide-img" data-toggle="modal" data-target=".amber4" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/amber5.jpg" className="slide-img" data-toggle="modal" data-target=".amber5" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/amber6.jpg" className="slide-img" data-toggle="modal" data-target=".amber6" style={{ width: "100%" }} />
                                            </div>

                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/amber7.jpg" className="slide-img" data-toggle="modal" data-target=".amber7" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/amber8.jpg" className="slide-img" data-toggle="modal" data-target=".amber8" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/amber2.jpg" className="slide-img" data-toggle="modal" data-target=".amber2" style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="amenities">
                                    <div className="row tab-1 text-white pl-4 pr-4" id="tb3">
                                        <div className="row mt-4">
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am1.png" style={{ width: "80px" }} /> <span className="am-p">Lawn with Seating</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am2.png" style={{ width: "80px" }} /> <span className="am-p">Cycle Track and Pathway</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am3.png" style={{ width: "80px" }} /> <span>Kids Play Area</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am4.png" style={{ width: "80px" }} /> <span>Outdoor Gym with Tree Court</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am5.png" style={{ width: "80px" }} /> <span>Car Parking</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am6.png" style={{ width: "80px" }} /> <span>Skating Rink</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am7.png" style={{ width: "80px" }} /> <span>Multi-Purpose Court</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am8.png" style={{ width: "80px" }} /> <span>Hopscotch</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am9.png" style={{ width: "80px" }} /> <span>Swimming Pool</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am10.png" style={{ width: "80px" }} /> <span>Multi-Purpose Hall</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am11.png" style={{ width: "80px" }} /> <span>Indoor Games</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am12.png" style={{ width: "80px" }} /> <span>Yoga-Gym-Aerobics</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am13.png" style={{ width: "80px" }} /> <span>Meditation Pavilion</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am14.png" style={{ width: "80px" }} /> <span>Transformer Yard</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3 mobam">
                                                <p><img src="../Images/am15.png" style={{ width: "80px" }} /> <span>Reflexology Pathway</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="spec">
                                    <div className="row tab-1 text-white" id="tb4">
                                        <div className="row mt-4 pb-4 ">
                                            <div className="col-md-6 text-justify ptsans-14 lh-2 mb-5">
                                                <div class="row specw">
													<div class="col-md-3 mt-4 pb-4 ">
														<img src="../Images/office-building.png" alt="Percentage" style ={{ width: "80px" }}  />												
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">STRUCTURE</p>
														• R.C.C. framed structure to withstand wind & seismic loads as per IS code. Solid cement concrete block masonry 100mm, 150mm & 200mm.
													</div>
													
													<div class="col-md-3 mt-4 pb-4 ">
														<img src="../Images/door.png" alt="Percentage" style ={{ width: "80px" }}  />													
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">DOORS</p>
														• Main doors: Engineered hard wood frame with designer shutters of 35 mm thickness with melamine polish finished on both sides. <br/>• Internal doors: Engineered hard wood frames with flush shutters of 32 mm thickness with enamel paint finished on both sides. <br/>• French doors: UPVC door systems with sliding shutters with provision for mosquito mesh. <br/>• Windows: UPVC window systems with safety grill (M.S) and provision for mosquito mesh & Aluminum for Toilet ventilators & Staircase windows. <br/>• All Hardware of reputed make.
													</div>
													<br/>
													<br/>
													<br/>
                                                    <br/>
													
													<div class="col-md-3 mt-4 pb-4 ">
														<img src="../Images/paint-roller.png" alt="Percentage" style ={{ width: "80px" }}  />
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">PAINTINGS</p>
                                                        • External: One coat primer and two coats of external emulsion paint. <br/>• Internal: Smooth putty finish with two coats of Tractor emulsion paint for walls and O.B.D paint for ceiling over a coat of primer.
													</div>
													
													<br/>
													<div class="col-md-3 mt-4 pb-4">
														<img src="../Images/floor.png" alt="Percentage" style ={{ width: "80px" }}  />
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">FLOORING</p>
														• Staircases: Granite flooring.<br/> • Living, Dining, all Bedrooms, Kitchen & Corridor: 600 X 600 mm size Nano vitrified tiles.<br/> • Bathrooms: 300x300mm Satin finish ceramic tile flooring.<br/> • All Balconies/Utilities: 300x300mm Rustic ceramic tiles.<br/> • Dadoing in Kitchen: 300x450mm Glazed ceramic tiles dado up to 2' height above kitchen platform level.<br/> • Bathrooms: 300x450 Glazed ceramic tile dado up to False-Ceiling level.<br/> • Basement: cement concrete power troweled with smooth finish.
													</div>
													<br/>
													<br/>
													<br/>
                                                    <br/>
                                                    <br/>
                                                    <br/>
													<div class="col-md-3 mt-4 pb-4 ">
														<img src="../Images/stairs.png" alt="Percentage" style ={{ width: "80px" }}  />														 
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">HAND RAILING</p>
														• Balcony: M.S railing.<br/> • Staircase (common area): M.S railing.
													</div>
													<br/>
													
													<div class="col-md-3 mt-4 pb-4">
														<img src="../Images/kitchen.png" alt="Percentage" style ={{ width: "80px" }}  />
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">KITCHEN</p>
														• Provision for softened water outlet.
													</div>
													
													<div class="col-md-3 mt-4 pb-4 ">
														<img src="../Images/bathroom.png" alt="Percentage" style ={{ width: "80px" }}  />
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">BATHROOMS</p>
														• Pedestal wash basin.<br/> • Floor mounted EWC.<br/> • Sanitary: Cera or Hindware or Equivalent.<br/> • C.P Fittings:  Jaquar or Cera or Equivalent.
                                                    <br/>
													<br/>
													<br/>
													</div>	
													</div>
                                               </div>
                                            <div className="col-md-6 text-justify ptsans-14 lh-2">
                                                <div class="row specw">
													<div class="col-md-3 mt-4 pb-4 ">
														<img src="../Images/pipeline.png" alt="Percentage" style ={{ width: "80px" }}  />	
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">PLUMBING</p>
														• Drainage/sewage: PVC pipes & fittings.<br/> • Water supply (Int & Ext): Cpvc or Upvc pipes & fittings.
													</div>
													<br/>
													<div class="col-md-3 mt-4 pb-4">
														<img src="../Images/usb-port.png" alt="Percentage" style ={{ width: "80px" }}  />
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">TELECOM/ INTERNET/ CABLE TV</p>
														• Provision for internet, DTH, telephone & intercom.
													</div>
													<div class="col-md-3 mt-4 pb-4">
														<img src="../Images/cyber-security.png" alt="Percentage" style ={{ width: "80px" }}  />
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">SECURITY</p>
														• Round-the-clock security system.<br/> • Intercom facility to all apartments connecting to security room.<br/> • Panic button and intercom is provided in the lifts.<br/> • Solar power fencing around the compound.<br/> • Surveillance cameras at the main security and entrance of each Wing.
													</div>
													<br/>
													<br/>
													<br/>
                                                    <br/>
													<div class="col-md-3 mt-4 pb-4 ">
														<img src="../Images/elevator.png" alt="Percentage" style ={{ width: "80px" }}  />
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">LIFTS</p>
														• One Eight passenger & one thirteen passenger Lift for Each Block with auto rescue device with V3F for energy efficiency. (Schindler or Johnson or Equivalent make)
													</div>
													<div class="col-md-3 mt-4 pb-4 ">
														<img src="../Images/gear.png" alt="Percentage" style ={{ width: "80px" }}  />
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">WTP &amp; STP</p>
														• Softened water made available through an exclusive water treatment plant (in case of bore water).<br/> • Sewage treatment plant of adequate capacity as per norms will be provided inside the project, treated sewage water will be used for the landscaping / flushing purpose.
													</div>
													<br/>
													<div class="col-md-3 mt-4 pb-4 ">
														<img src="../Images/golf-club.png" alt="Percentage" style ={{ width: "80px" }}  />										
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">CLUB HOUSE AMENITIES</p>
														• Ground floor: Entrance Lobby, Multipurpose Hall, Gymnasium, Party lawn, Adult pool, Kids pool, pool deck area & Change rooms.<br/> • First floor:  Yoga/Meditation/Aerobics, Indoor games & Guest room (1no.).
													</div>
													<br/>
													
													<div class="col-md-3 mt-4 pb-4 ">
														<img src="../Images/electric.png" alt="Percentage" style ={{ width: "80px" }}  />														
													</div>
													<div class="col-md-9 mt-4 pb-4" text-justify>
														<p class="hs11 font-weight-bold">ELECTRICAL</p>
														• Concealed copper wiring of Polycab or Anchor or Equivalent.<br/> • Modular switches: Schneider or Anchor or Equivalent.<br/> • Power outlets for air conditioners in Master Bedroom.<br/> • Power outlets for geysers and Exhaust Fans in all bathrooms.<br/> • Power outlets in kitchen for Hob, chimney, refrigerator, microwave oven, mixer and washing machine in utility area.<br/> • 100 % DG backup power for Lifts, Pumps & lighting in common areas.
                                                        
													</div>
													</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="walk">
                                    <div className="row tab-1 text-center" id="tb4">
                                        <iframe width="100%" height="420" src="https://www.youtube.com/embed/egJyT0UYmzk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* Aurum Advantage */}
                
                <div className="aspire-red"></div>
                {/* Real Value */}
                <img src="../Images/lr.png" alt="lr" className="l-aspire" />
                <div className="container pl-5 pr-5 ">
                    <div className="row">
                        <div className="col-md-12 text-center pb-5 pt-5">
                            <h2 className="aspireblue text-center text-Montserrat-2 pb-4">REAL PROXIMITY. REAL VALUE.</h2>
                            <p className="mob-justify pb-3 lh-2">Aspire Amber is admirably well connected with city infrastructure owing to its expertly picked central location.</p>
                            <img src="../Images/Amber_NEIGHBOURHOOD.jpg" alt="Floor Plan" className="w-100 f-plan" data-toggle="modal" data-target=".Amber_NEIGHBOURHOOD" />
                        </div>
                    </div>
                </div>
                {/* Real Life Style */}
                <div className="bg-aspiregray">
                    <div className="container pl-5 pr-5 ">
                        <div className="row">
                            <div className="col-md-12 text-center pb-4 pt-4">
                                <h2 className="aspireblue text-center text-Montserrat-2 pt-5">REAL LIFESTYLE. REAL VALUE.</h2>
                                <p className="mob-justify pt-3 pb-5 lh-2">The Amber lives upto the expectations of a new home buyer who demands great quality and a host of amenities at a reasonable price. In design, construction; open space, air flow; sunlight, greenery and executive comfort. Aspire Amber not only reflects your individualistic lifestyle, it also helps you flourish and blossom. After all, you want your home to be a haven that you come back to and it's natural that you want your personal space to be peaceful and most relaxing.</p>
                                <div class="row lcol" >
                                    <div class="container">

                                  <div class="cardBox">
                                    <div class="card" style={{ height: "300px" }}>
                                      <div class="front">
                                        <h3>AN AMAZING VALUE FOR LIFE STYLE.</h3>
                                        <p>PERFECT HAPPY HOMES FOR AMIBITIOUS YOUNG INDIAN TO SETTLE IN.</p>
                                      </div>
                                      <div class="back">
                                        <p>The Amber lives up to the expectations of a new home buyer who demands great quality and a host of amenities at a reasonable price. In design, construction; open space, air-flow; sunlight, greenery and executive comfort, Amber is absolute tops.</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="cardBox">
                                    <div class="card" style={{ height: "300px" }}>
                                      <div class="front">
                                        <h3>AMBERING. LIFESTYLE, YOUR WAY.</h3>
                                        <p><br/>RELAXING.<br/>PEACEFUL.</p>
                                      </div>
                                      <div class="back">
                                        <p>Aspire Amber not only reflects your individualistic lifestyle, it also helps you flourish and blossom. After all, you want your home to be a haven that you come back to and it's natural that you want your own personal space to be peaceful and most relaxing.</p> 
                                      </div>
                                    </div>
                                  </div>

                                  <div class="cardBox">
                                    <div class="card" style={{ height: "300px" }}>
                                      <div class="front">
                                        <h3>WORKING FROM HOME IS SUPER DELIGHTFUL</h3>
                                        <p><br/>GREAT SPACE.<br/>GREAT AMENITIES.</p>
                                      </div>
                                      <div class="back">
                                        <p>Aspire Amber is packed to the rooftop with great amenities, Saunter into the plush clubhouse and you have swimming pool facilities for indoor sports and games, a gymnasium, yoga room and more.</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="cardBox">
                                    <div class="card" style={{ height: "300px" }}>
                                      <div class="front">
                                        <h3>OWN HOME. RENT FREE. STRESS FREE</h3>
                                        <p><br/>HAPPY HOME. <br/> HAPPY MOMENTS.</p>
                                      </div>
                                      <div class="back">
                                        <p>Aspire Amber makes it possible to cast off on your own and live your dream, just when you are on the cusp of the most aspiring time of your life. Your hard work and early success in life deserve only an Amber home.</p>
                                      </div>
                                    </div>
                                  </div>

                                    </div>
			
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Amenities */}
                <div className=" bg-aspireblue">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center pb-4 pt-4 pl-5 pr-5">
                                <h2 className="text-white text-center text-Montserrat-2 pt-5">REAL AMENITIES. REAL VALUE.</h2>
                                <div className="row pt-5">
                                    <div className="col-md-3 pb-3">
                                        <div class="card" style= {{ height:"225px !important" }}>
                                            <img class="card-img-top" src="../Images/1a.png" alt="Card image" />
                                            <div class="card-body-cus">
                                                <p class="card-text font-weight-bold">Club house and Basketball Court</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pb-3">
                                        <div class="card" style= {{ height:"225px" }}>
                                            <img class="card-img-top" src="../Images/2a.png" alt="Card image" />
                                            <div class="card-body-cus">
                                                <p class="card-text font-weight-bold">Swimming Pool</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pb-3">
                                        <div class="card" style= {{ height:"225px" }}>
                                            <img class="card-img-top" src="../Images/3a.png" alt="Card image" />
                                            <div class="card-body-cus">
                                                <p class="card-text font-weight-bold">Gym</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pb-3">
                                        <div class="card" style= {{ height:"225px" }}>
                                            <img class="card-img-top" src="../Images/4a.png" alt="Card image" />
                                            <div class="card-body-cus">
                                                <p class="card-text font-weight-bold">Multi-purpose Hall</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className=" text-white mob-justify pt-3 pb-2 lh-2">Aspire Amber is packed to the rooftop with great amenities. Saunter into the plush clubhouse and you have swimming pools, facilities for indoor sports and games, a gymnasium, yoga room and more. Amber across the sprawling open areas and enjoy a tree court with lawn, track for jogging, skating rink, amphitheatre with feature wall, senior zone for peace and quiet and even a path for reflexology.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aspire-red"></div>

                {/* Approvals */}
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-md-12  pb-4 pt-4">
                            <h2 className="aspireblue text-left text-Montserrat-2 pt-5" style={{ display: "inline" }}>LOCATION MAP</h2>
                        </div>
                        <a href="https://goo.gl/maps/7WnCoT93fEPC7gVR8" target="_blank"><img src="../Images/AMBER_locationMap.jpg" alt="map" className="w-100 pt-3 pb-5" /></a>
                    </div>
                </div>
                <div className="aspire-red"></div>

                <div className="container pt-5 pb-5 pl-5 pr-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="aspireblue text-center text-Montserrat-2 pb-4">APPROVALS.</h2>
                        </div>
                    </div>
                    <div className="row" style={{ overflow: "auto !important" }}>
                        <table class="table table-striped" >
                            <thead>
                                <tr>
                                    <th scope="col">Sl No.</th>
                                    <th scope="col">List Of Approval</th>
                                    <th scope="col">Sanction Authority</th>
                                    <th scope="col">Date of Approval</th>
                                    <th scope="col">Approval No.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>BDA Approval</td>
                                    <td>Bangalore Development Authority</td>
                                    <td>10/11/2021</td>
                                    <td>BDA/PS/EM/EO-1/TA-4/EAST/16/2020-21</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Water Permission -PDO</td>
                                    <td>Panchayath Water Permission Office</td>
                                    <td>18/01/2021</td>
                                    <td>PDO/Doddabanahalli/01/2020-21.</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>KSPCB</td>
                                    <td>Karnataka State Pollution Control Board</td>
                                    <td>24/08/2021	</td>
                                    <td>CTE-326371 & PCB/26/INFRA/2021</td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>SEIAA</td>
                                    <td>State Level Environment Impact Assessment Authority-Karnataka</td>
                                    <td>06/04/2021</td>
                                    <td>SEIAA/139/CON/2020</td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>FIRE</td>
                                    <td>Karnataka State Fire and Emergency Services</td>
                                    <td>15/01/2021</td>
                                    <td>KSFES/GBC/(1)/409 & DOCKET NO. KSFES/NOS/304/2020</td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>BSNL</td>
                                    <td>Bharat Sanchar Nigam Limited</td>
                                    <td>10/01/2020</td>
                                    <td>No. DE/SAN/BG/NOC-HRB (S-11)/2019-20/69 @Dated BG-41</td>
                                </tr>
                                <tr>
                                    <th scope="row">7</th>
                                    <td>AAI</td>
                                    <td>Airport Authority of India</td>
                                    <td>12/02/2020	</td>
                                    <td>BIAL/SOUTH/B/010620/437842</td>
                                </tr>
                                <tr>
                                    <th scope="row">8</th>
                                    <td>BESCOM</td>
                                    <td>Bangalore Electrical Supply and Company</td>
                                    <td>08/03/2021</td>
                                    <td>No.SEE/BRC/EE(O)/AEE(0)/F-1(A)/2010-21/6365-66</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
		<div className="aspire-red"></div>

		                    {/* Financing */}
	                    <div className="container pt-5">
		                        <div className="row">
		                            <div className="col-md-12  pb-4 pt-4">
		                                <h2 className="aspireblue text-left text-Montserrat-2 pt-5" style={{ display: "inline" }}>FINANCING</h2>
		                            </div>
		                            <img src="../Images/Aspire-Amber-BHFL.jpg" alt="map" className="w-100 pt-3 pb-5" />
		                        </div>
		                    </div>
                {/* Calculator */}
                <div className="bg-aspiregray pt-3 pb-5" id="cal">
                    <div className="container pl-5 pr-5 ">
                        <div className="row">
                            <div className="col-md-12 text-center pb-4 pt-4">
                                <h4 className="aspireblue text-center text-Montserrat-2">EMI CALCULATOR</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 text-center pb-3 mpb-3">
                                        <span className="sign"><i class="fa fa-inr" style={{ paddingLeft: "4px" }, { paddingRight: "4px" }} aria-hidden="true"></i></span><input type="number" placeholder="Property Price" className="input" id="emi_pp" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center pb-3 mpb-3">
                                        <span className="sign"><i class="fa fa-inr" style={{ paddingLeft: "4px" }, { paddingRight: "4px" }} aria-hidden="true"></i></span><input type="number" placeholder="Down Payment" className="input" id="emi_dp" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center pb-3 mpb-3">
                                        <span className="sign"><i class="fa fa-calendar" aria-hidden="true"></i></span><input type="number" placeholder="Duration Year" className="input" id="emi_dy" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center pb-3 mpb-3">
                                        <span className="sign"><i class="fa fa-percent" aria-hidden="true"></i></span><input type="number" placeholder="Interest Rate" className="input" id="emi_ir" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center pb-1 w-100">
                                        <button className="aurum-btn mt-3 mb-5" id="emi_btn">CALCULATE INSTALMENT</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-4 bg-aspireblue text-white pt-3 pb-3">
                                Monthly Payment (EMI)
                        </div>
                            <div className="col-md-4 bg-aspireblue text-white pt-3 pb-3">
                                Total Interest Payable
                        </div>
                            <div className="col-md-4 bg-aspireblue text-white pt-3 pb-3">
                                Total of Payments (Principal + Interest)
                        </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-4 bg-white pt-3 pb-3" id="emi-in">
                                0/-
                        </div>
                            <div className="col-md-4 bg-white pt-3 pb-3" id="int-in">
                                0/-
                        </div>
                            <div className="col-md-4 bg-white pt-3 pb-3" id="tot-in">
                                0/-
                        </div>
                        </div>

                    </div>
                </div>
                <div class="modal fade Amber_NEIGHBOURHOOD" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/Amber_NEIGHBOURHOOD.jpg" alt="Map" className="img-pop" />
                        </div>
                    </div>
                </div>
                <div class="modal fade amber_Master_Plan" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg fplan-modal">
                        <div class="modal-content">
                            <img src="../Images/amber_Master_Plan.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                {/* Floor Plan */}
                <div class="modal fade f11" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/f11.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade f12" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/f12.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f13" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/f13.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f14" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/f14.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f15" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/f15.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f16" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/f16.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f17" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/f17.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f18" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/f18.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f19" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/f19.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f20" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/f20.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f21" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/f21.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                {/* Images */}

                <div class="modal fade amber1" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/amber1.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade amber2" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/amber2.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade amber3" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/amber3.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade amber4" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/amber4.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade amber5" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/amber5.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade amber6" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/amber6.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade amber7" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/amber7.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade amber8" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/amber8.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <script src="../Scripts/aurum_cal.js"></script>
		    <script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="22824004"></script>
            </Layout >
        )
    }
}

export default Amber
